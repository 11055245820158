.container-fluid {
  max-width: 85% !important;

}
.container{
  max-width: 90% !important;
  border:1px solid rgba(0, 0, 0, 0.125);;
}

.modal-90w{
  max-width: 90vw !important;
}
.navbar-text{
  color: black !important;
  font-size: 20px;
}

hr{
  margin: 0 !important;
}

[data-letters]:before {
  content:attr(data-letters);
  display:inline-block;
  font-size:2em;
  width:2.5em;
  height:2.5em;
  line-height:2.5em;
  text-align:center;
  border-radius:50%;
  background: #B61D35;
  opacity: 0.6;
  vertical-align:middle;
  margin-top:1em;
  color:white;
  
}

[data-letters-list]:before {
  content: attr(data-letters-list);
  display: inline-block;
  font-size: 1.7em;
  width: 1.2em;
  height: 1.2em;
  line-height: 1.2em;
  text-align: center;
  border-radius: 50%;
  background: #B61D35;
  opacity: 0.6;
  vertical-align: middle;
  margin-top: 0.5em;
  color: white;}

.fullScreenSpinner{
  opacity:    0.5;
  background: #fff;
  width:      100%;
  height:     100%;
  z-index:    100000;
  top:        0;
  left:       0;
  position:   fixed;
}
