body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a:-webkit-any-link {
  text-decoration: none;
}

.description {
  max-width: 100%;
  word-wrap: break-word; /* Breaks words at the boundary if they are too long */
  white-space: pre-line; /* Preserves line breaks */
}





