.red{
    color: #B61D35;
}

.green{
    color: #40826D;
}

.blue{
    color: #0096FF;
}

.typo-grey{
    color: #7393B3;
}

.warning{
    color: #CC5500;
}

.success{
    color: #50C878;
}

.bg-red{
    background-color: #B61D35 !important;
}

.bg-green{
    background-color: #40826D;
}

.bg-blue{
    background-color: #0096FF;
}

.bg-grey{
    background-color: #F2F4F6;
}

.bg-input{
    background-color: #EEF8FD;
}